import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from "react-helmet";
import Collections from "../components/Collections";

const IndexPage = ({
   data: {
       allMarkdownRemark: { group },
       site: {
           siteMetadata: { title },
       },
   },
}) => {
  return (
      <Layout>
          <Helmet titleTemplate="%s | Painting by Olena Isai">
              <title>{title}</title>
              <meta
                  name="description"
                  content="Olena Isai paintings"
              />
          </Helmet>
          <>
              <div className="has-text-centered is-size-2 pt-6 pb-4 color-purple">Olena Isai</div>
              <div className="content is-vcentered has-text-centered py-6 my-6">
                  <Collections collections={group} />
              </div>
          </>
      </Layout>
  )
};

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___collections) {
        fieldValue
        totalCount
      }
    }
  }
`
